var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"cv-accordion-item",class:[
    `${_vm.carbonPrefix}--accordion__item`,

    {
      [`${_vm.carbonPrefix}--accordion__item--disabled`]: _vm.disabled,
      [`${_vm.carbonPrefix}--accordion__item--active`]: _vm.dataOpen,
      [`${_vm.carbonPrefix}--accordion__item--${this.animation}`]: _vm.animation,
    },
  ],attrs:{"data-accordion-item":""},on:{"animationend":_vm.onAnimationEnd}},[_c('button',{ref:"button",class:`${_vm.carbonPrefix}--accordion__heading`,attrs:{"disabled":_vm.disabled,"type":"button","aria-expanded":_vm.dataOpen ? 'true' : 'false',"aria-controls":_vm.uid},on:{"click":_vm.toggle}},[_c('ChevronRight16',{class:`${_vm.carbonPrefix}--accordion__arrow`}),_c('p',{class:`${_vm.carbonPrefix}--accordion__title`},[_vm._t("title")],2)],1),_c('div',{class:`${_vm.carbonPrefix}--accordion__content`,attrs:{"id":_vm.uid}},[_vm._t("content")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }