var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`cv-pagination ${_vm.carbonPrefix}--pagination`,attrs:{"data-pagination":""}},[_c('div',{class:`${_vm.carbonPrefix}--pagination__left`},[_c('cv-select',{ref:"pageSizeSelect",class:`${_vm.carbonPrefix}--select__item-count`,attrs:{"label":`${_vm.pageSizesLabel}`,"inline":"","value":`${_vm.pageSizeValue}`},on:{"change":_vm.onPageSizeChange}},_vm._l((_vm.pageSizes),function(size,index){return _c('cv-select-option',{key:index,attrs:{"value":`${size.value ? size.value : size}`}},[_vm._v(_vm._s(size.label ? size.label : size.value ? size.value : size))])}),1),_c('span',{class:`${_vm.carbonPrefix}--pagination__text`},[_c('span',{attrs:{"data-displayed-item-range":""}},[_vm._t("range-text",function(){return [_vm._v(_vm._s(_vm.rangeText))]},{"scope":_vm.rangeProps})],2)])],1),_c('div',{class:`${_vm.carbonPrefix}--pagination__right`},[(_vm.numberOfItems !== Infinity)?_c('cv-select',{ref:"pageSelect",class:`${_vm.carbonPrefix}--select__page-number`,attrs:{"label":`${_vm.pageNumberLabel}:`,"inline":"","hideLabel":"","value":`${_vm.pageValue}`},on:{"change":_vm.onPageChange}},_vm._l((_vm.pages),function(pageNumber){return _c('cv-select-option',{key:pageNumber,attrs:{"value":`${pageNumber}`,"selected":_vm.pageValue === pageNumber}},[_vm._v(_vm._s(pageNumber))])}),1):_vm._e(),_c('span',{class:`${_vm.carbonPrefix}--pagination__text`},[_vm._t("of-n-pages",function(){return [_vm._v(_vm._s(_vm.pageOfPages))]},{"scope":_vm.ofNPagesProps})],2),_c('button',{class:[
        `${_vm.carbonPrefix}--pagination__button ${_vm.carbonPrefix}--pagination__button--backward`,
        { [`${_vm.carbonPrefix}--pagination__button--no-index`]: _vm.noWayBack },
      ],attrs:{"type":"button","data-page-backward":"","aria-label":_vm.backwardText,"disabled":_vm.noWayBack},on:{"click":_vm.onPrevPage}},[_c('CaretLeft16',{class:`${_vm.carbonPrefix}--pagination__button-icon`})],1),_c('button',{class:[
        `${_vm.carbonPrefix}--pagination__button ${_vm.carbonPrefix}--pagination__button--forward`,
        { [`${_vm.carbonPrefix}--pagination__button--no-index`]: _vm.noWayForward },
      ],attrs:{"type":"button","data-page-forward":"","aria-label":_vm.forwardText,"disabled":_vm.noWayForward},on:{"click":_vm.onNextPage}},[_c('CaretRight16',{class:`${_vm.carbonPrefix}--pagination__button-icon`})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }