var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g({staticClass:"cv-button",class:[
    ..._vm.buttonClassOpts({ iconOnly: true }),
    `${_vm.carbonPrefix}--tooltip__trigger`,
    `${_vm.carbonPrefix}--tooltip--a11y`,
    `${_vm.carbonPrefix}--tooltip--${_vm.tipPosition || 'bottom'}`,
    `${_vm.carbonPrefix}--tooltip--align-${_vm.tipAlignment || 'center'}`,
  ],attrs:{"aria-pressed":_vm.kind === 'ghost' && _vm.selected,"type":"button"}},_vm.inputListeners),[_c('span',{class:`${_vm.carbonPrefix}--assistive-text`},[_vm._v(_vm._s(_vm.label))]),_vm._t("icon",function(){return [(_vm.icon || _vm.iconHref)?_c('CvSvg',{class:`${_vm.carbonPrefix}--btn__icon`,attrs:{"svg":_vm.icon || _vm.iconHref}}):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }