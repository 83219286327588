var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cv-grid",class:[
    `${_vm.carbonPrefix}--grid`,
    {
      [`${_vm.carbonPrefix}--grid--full-width`]: _vm.fullWidth,
      [`${_vm.carbonPrefix}--grid--condensed`]: _vm.kind === 'condensed',
      [`${_vm.carbonPrefix}--grid--narrow`]: _vm.kind === 'narrow',
    },
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }