var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[
    `cv-overflow-menu-item ${_vm.carbonPrefix}--overflow-menu-options__option`,
    {
      [`${_vm.carbonPrefix}--overflow-menu-options__option--disabled`]: _vm.disabled,
      [`${_vm.carbonPrefix}--overflow-menu-options__option--danger`]: _vm.danger,
    },
  ],on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.onEsc.apply(null, arguments)}}},[_c('button',_vm._g({class:`${_vm.carbonPrefix}--overflow-menu-options__btn`,attrs:{"type":"button","data-floating-menu-primary-focus":_vm.primaryFocus,"disabled":_vm.disabled},on:{"click":_vm.onClick}},_vm.$listeners),[_c('span',{class:`${_vm.carbonPrefix}--overflow-menu-options__option-content`},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }