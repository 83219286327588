var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cv-wrapper',{class:`cv-search ${_vm.carbonPrefix}--form-item`,attrs:{"tag-type":_vm.formItem ? 'div' : ''}},[_c('div',_vm._b({ref:"search",class:[
      `${_vm.carbonPrefix}--search`,
      {
        [`${_vm.carbonPrefix}--search--light`]: _vm.isLight,
        [`${_vm.carbonPrefix}--search--${_vm.internalSize}`]: _vm.internalSize,
        [`${_vm.carbonPrefix}--toolbar-search`]: _vm.isToolbarKind,
        [`${_vm.carbonPrefix}--toolbar-search--active`]: _vm.toolbarActive,
        'cv-search': !_vm.formItem,
      },
    ],attrs:{"role":"search"}},'div',_vm.wrapperAttrs,false),[(_vm.isToolbarKind)?_c('button',{class:`${_vm.carbonPrefix}--toolbar-search__btn`,attrs:{"type":"button","aria-label":_vm.toolbarAriaLabel},on:{"click":function($event){return _vm.toggleActive(true)},"blur":_vm.checkFocus}},[_c(_vm.icon,{tag:"component",class:`${_vm.carbonPrefix}--search-magnifier`})],1):_vm._e(),(!_vm.isToolbarKind)?_c('div',{class:`${_vm.carbonPrefix}--search-magnifier`},[_c('Search20',{class:`${_vm.carbonPrefix}--search-magnifier-icon`})],1):_vm._e(),_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label),expression:"label"}],class:`${_vm.carbonPrefix}--label`,attrs:{"for":_vm.uid}},[_vm._v(_vm._s(_vm.label))]),_c('input',_vm._g(_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],ref:"input",class:`${_vm.carbonPrefix}--search-input`,attrs:{"id":_vm.uid,"type":"text","placeholder":_vm.placeholder,"aria-labelledby":_vm.uid},domProps:{"value":(_vm.internalValue)},on:{"blur":_vm.checkFocus,"input":function($event){if($event.target.composing)return;_vm.internalValue=$event.target.value}}},'input',_vm.$attrs,false),_vm.inputListeners)),_c('button',{class:[`${_vm.carbonPrefix}--search-close`, { [`${_vm.carbonPrefix}--search-close--hidden`]: !_vm.clearVisible }],attrs:{"type":"button","title":_vm.clearAriaLabel,"aria-label":_vm.clearAriaLabel},on:{"click":_vm.onClearClick}},[_c('Close16')],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }