var render = function render(){var _vm=this,_c=_vm._self._c;return _c(!_vm.isFilter && _vm.hasClickListener ? 'button' : 'div',_vm._g({tag:"component",class:[
    `cv-tag ${_vm.carbonPrefix}--tag`,
    `${_vm.carbonPrefix}--tag--${_vm.tagKind}`,
    {
      [`${_vm.carbonPrefix}--tag--disabled`]: _vm.disabled,
      [`${_vm.carbonPrefix}--tag--filter`]: _vm.isFilter,
      [`${_vm.carbonPrefix}--tag--interactive`]: _vm.hasClickListener,
      [`${_vm.carbonPrefix}--tag--${_vm.size}`]: _vm.size,
    },
  ],attrs:{"id":_vm.uid,"title":_vm.title},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();$event.preventDefault();return _vm.$emit('remove')},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.preventDefault();return _vm.$emit('remove')}}},this.$listeners),[(_vm.icon && !_vm.filter)?_c('div',{class:`${_vm.carbonPrefix}--tag__custom-icon`},[_c('CvSvg',{attrs:{"svg":_vm.icon}})],1):_vm._e(),_c('span',{class:`${_vm.carbonPrefix}--tag__label`,attrs:{"title":_vm.hasClickListener ? _vm.label : ''}},[_vm._v(_vm._s(_vm.label))]),(_vm.isFilter)?_c('button',{class:`${_vm.carbonPrefix}--tag__close-icon`,attrs:{"aria-label":_vm.clearAriaLabel,"disabled":_vm.disabled,"type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onRemove.apply(null, arguments)}}},[_c('Close16')],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }