var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._g({staticClass:"cv-header-menu-button",class:[
    `${_vm.carbonPrefix}--header__action`,
    `${_vm.carbonPrefix}--header__menu-trigger`,
    `${_vm.carbonPrefix}--header__menu-toggle`,
    {
      [`${_vm.carbonPrefix}--header__action--active`]: _vm.dataActive,
      [`${_vm.carbonPrefix}--header__menu-toggle__hidden`]: !_vm.hasRail,
    },
  ],attrs:{"type":"button","aria-haspopup":"true","aria-controls":_vm.ariaControls,"aria-expanded":_vm.active ? 'true' : 'false',"id":_vm.uid},on:{"click":_vm.gaToggle,"focusout":_vm.gaFocusout}},_vm.$listeners),[(_vm.dataActive)?_c('Close20'):_vm._e(),(!_vm.dataActive)?_c('Menu20'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }