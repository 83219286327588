var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{class:[
    `cv-tooltip`,
    `${_vm.carbonPrefix}--tooltip__trigger`,
    `${_vm.carbonPrefix}--tooltip--a11y`,
    `${_vm.carbonPrefix}--tooltip--${_vm.direction}`,
    `${_vm.carbonPrefix}--tooltip--align-${_vm.alignment}`,
  ],attrs:{"type":"button"}},[_c('span',{class:`${_vm.carbonPrefix}--assistive-text`},[_vm._v(_vm._s(_vm.tip))]),_vm._t("default",function(){return [_c('Information16')]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }