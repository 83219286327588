var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:[
    `${_vm.carbonPrefix}--accordion ${_vm.carbonPrefix}--skeleton`,
    {
      [`${_vm.carbonPrefix}--accordion--${_vm.align}`]: _vm.align,
      [`${_vm.carbonPrefix}--accordion--${_vm.size}`]: _vm.size,
    },
  ]},[_c('cv-accordion-item-skeleton',{attrs:{"open":true}},[_c('cv-skeleton-text',{attrs:{"width":"90%"}}),_c('cv-skeleton-text',{attrs:{"width":"80%"}}),_c('cv-skeleton-text',{attrs:{"width":"95%"}})],1),_c('cv-accordion-item-skeleton'),_c('cv-accordion-item-skeleton'),_c('cv-accordion-item-skeleton')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }