var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cv-wrapper',{class:`cv-date-picker ${_vm.carbonPrefix}--form-item`,attrs:{"tag-type":_vm.formItem ? 'div' : '',"id":_vm.uid}},[_c('div',{ref:"date-picker",class:[
      `${this.carbonPrefix}--date-picker ${this.carbonPrefix}--date-picker--${this.kind}`,
      {
        [`${_vm.carbonPrefix}--date-picker--simple`]: this.kind === 'short',
        [`${_vm.carbonPrefix}--date-picker--light`]: _vm.isLight,
        'cv-date-pciker': !_vm.formItem,
      },
    ],attrs:{"data-date-picker":['single', 'range'].includes(_vm.kind),"data-date-picker-type":_vm.kind,"id":_vm.formItem ? undefined : _vm.uid}},[_c('div',{class:{
        [`${_vm.carbonPrefix}--date-picker-container`]: ['single', 'range'].includes(_vm.kind),
        [`${_vm.carbonPrefix}--date-picker--nolabel`]: _vm.getDateLabel !== undefined,
      },on:{"change":_vm.onChange}},[(_vm.getDateLabel.length > 0)?_c('label',{class:`${_vm.carbonPrefix}--label`,attrs:{"for":`${_vm.uid}-input-1`}},[_vm._v(" "+_vm._s(_vm.getDateLabel)+" ")]):_vm._e(),_c('div',{class:`${_vm.carbonPrefix}--date-picker-input__wrapper`},[_c('input',{ref:"date",class:`${_vm.carbonPrefix}--date-picker__input`,attrs:{"data-invalid":_vm.isInvalid,"type":"text","id":`${_vm.uid}-input-1`,"pattern":_vm.pattern,"placeholder":_vm.placeholder,"data-date-picker-input":"","data-date-picker-input-from":_vm.kind === 'range'}}),(['single', 'range'].includes(_vm.kind))?_c('Calendar16',{class:`${_vm.carbonPrefix}--date-picker__icon`,attrs:{"data-date-picker-icon":""},on:{"click":function($event){return _vm.cal.open()}}}):_vm._e()],1),(_vm.isInvalid)?_c('div',{class:`${_vm.carbonPrefix}--form-requirement`},[_vm._t("invalid-message",function(){return [_vm._v(_vm._s(_vm.invalidMessage || _vm.invalidDateMessage))]})],2):_vm._e()]),(_vm.kind === 'range')?_c('div',{class:{ [`${_vm.carbonPrefix}--date-picker-container`]: _vm.kind === 'range' }},[(_vm.getDateEndLabel.length > 0)?_c('label',{class:`${_vm.carbonPrefix}--label`,attrs:{"for":`${_vm.uid}-input-2`}},[_vm._v(" "+_vm._s(_vm.getDateEndLabel)+" ")]):_vm._e(),_c('div',{class:`${_vm.carbonPrefix}--date-picker-input__wrapper`},[_c('input',{ref:"todate",class:`${_vm.carbonPrefix}--date-picker__input`,attrs:{"type":"text","id":`${_vm.uid}-input-2`,"pattern":_vm.pattern,"placeholder":_vm.placeholder,"data-date-picker-input":"","data-date-picker-input-to":_vm.kind === 'range'}}),_c('Calendar16',{class:`${_vm.carbonPrefix}--date-picker__icon`,attrs:{"data-date-picker-icon":""},on:{"click":function($event){return _vm.openTodateCal()}}})],1)]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }