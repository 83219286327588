var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{class:[
    `cv-accordion ${_vm.carbonPrefix}--accordion`,
    {
      [`${_vm.carbonPrefix}--accordion--${_vm.align}`]: _vm.align,
      [`${_vm.carbonPrefix}--accordion--${_vm.size}`]: _vm.size,
    },
  ],attrs:{"data-accordion":""}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }