var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{class:[
    `cv-progress-step ${_vm.carbonPrefix}--progress-step`,
    `${_vm.carbonPrefix}--progress-step--${_vm.stateString}`,
    {
      [`${_vm.carbonPrefix}--progress-step--disabled`]: _vm.disabled,
      [`${_vm.carbonPrefix}--progress-step--invalid`]: _vm.invalid,
    },
  ],attrs:{"aria-disabled":_vm.disabled}},[_c(_vm.stepIcon,{tag:"conponent",class:_vm.stepClass},[_c('title',[_vm._v(_vm._s(_vm.description))])]),_c('p',{ref:"label",class:[`${_vm.carbonPrefix}--progress-label`, { [`${_vm.carbonPrefix}--progress-label-overflow`]: _vm.showOverflow }],on:{"mouseenter":_vm.onMouseEnter,"mouseleave":_vm.onMouseLeave,"click":function($event){return _vm.$emit('step-clicked', $event)}}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{class:`${_vm.carbonPrefix}--tooltip`,attrs:{"role":"tooltip","data-floating-menu-direction":"bottom"}},[_c('span',{class:`${_vm.carbonPrefix}--tooltip__caret`}),_c('p',{class:`${_vm.carbonPrefix}--tooltip__text`},[_vm._v(_vm._s(_vm.tip))])]),(_vm.additionalInfo)?_c('p',{class:`${_vm.carbonPrefix}--progress-optional`},[_vm._v(_vm._s(_vm.additionalInfo))]):_vm._e(),_c('span',{class:`${_vm.carbonPrefix}--progress-line`})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }