var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tagType,_vm._g(_vm._b({tag:"component",staticClass:"cv-link",class:[
    `${_vm.carbonPrefix}--link`,
    {
      [`${_vm.carbonPrefix}--link--disabled`]: _vm.disabled,
      [`${_vm.carbonPrefix}--link--inline`]: _vm.inline,
    },
  ]},'component',_vm.linkProps,false),_vm.$listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }