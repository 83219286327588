var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{class:[
    `cv-side-nav`,
    `${_vm.carbonPrefix}--side-nav`,
    `${_vm.carbonPrefix}--side-nav__navigation`,
    {
      [`${_vm.carbonPrefix}--side-nav--expanded`]: _vm.panelExpanded,
      [`${_vm.carbonPrefix}--side-nav--rail`]: _vm.rail,
      [`${_vm.carbonPrefix}--side-nav--collapsed`]: !_vm.panelExpanded && _vm.fixed,
      [`${_vm.carbonPrefix}--side-nav--ux`]: _vm.isChildOfHeader,
    },
  ],attrs:{"aria-hidden":!_vm.panelExpanded && !_vm.fixed ? 'true' : 'false',"id":_vm.id},on:{"focusout":_vm.onFocusout,"mousedown":_vm.onMouseDown}},[_vm._t("default"),(!_vm.fixed && !_vm.rail && !_vm.headerEmbedded)?_c('cv-side-nav-footer',{attrs:{"expanded":_vm.panelExpanded,"assistiveText":_vm.assistiveToggleText},on:{"toggle-expand":_vm.toggleExpand}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }