var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cv-code-snippet-oneline",class:[
    `${_vm.carbonPrefix}--snippet`,
    `${_vm.carbonPrefix}--snippet--single`,
    {
      [`${_vm.carbonPrefix}--snippet--disabled`]: _vm.disabled,
    },
  ]},[_c('div',{class:`${_vm.carbonPrefix}--snippet-container`},[_c('pre',[_vm._t("default")],2)]),(!_vm.hideCopyButton)?_c('cv-feedback-button',{attrs:{"disabled":_vm.disabled,"feedback":_vm.copyFeedback,"ariaLabel":_vm.feedbackAriaLabel,"title":_vm.feedbackAriaLabel},on:{"click":function($event){return _vm.$emit('copy-code')}}},[_c('Copy16',{class:`${_vm.carbonPrefix}--snippet__icon`})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }