var render = function render(){var _vm=this,_c=_vm._self._c;return _c('th',{style:(_vm.skeleton && _vm.headingStyle),attrs:{"aria-sort":_vm.internalOrder,"id":_vm.uid}},[(_vm.sortable)?_c('button',{class:[
      `${_vm.carbonPrefix}--table-sort`,
      {
        [`${_vm.carbonPrefix}--table-sort--active`]: this.internalOrder === 'descending',
        [`${_vm.carbonPrefix}--table-sort--active ${_vm.carbonPrefix}--table-sort--ascending`]:
          this.internalOrder === 'ascending',
      },
    ],style:(_vm.headingStyle),attrs:{"type":"button"},on:{"click":_vm.onSortClick}},[_c('cv-wrapper',{class:`${_vm.carbonPrefix}--table-header-label`,attrs:{"tag-type":_vm.headingLabelTag}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.heading))]})],2),_c('ArrowDown16',{class:`${_vm.carbonPrefix}--table-sort__icon`}),_c('Arrows16',{class:`${_vm.carbonPrefix}--table-sort__icon-unsorted`})],1):_c('cv-wrapper',{class:`${_vm.carbonPrefix}--table-header-label`,style:(_vm.headingStyle),attrs:{"tag-type":_vm.headingLabelTag}},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.heading))]})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }