var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cv-checkbox",class:[`${_vm.carbonPrefix}--checkbox-wrapper`, { [`${_vm.carbonPrefix}--form-item`]: _vm.formItem }]},[_c('input',_vm._g(_vm._b({ref:"input",class:`${_vm.carbonPrefix}--checkbox`,attrs:{"type":"checkbox","id":_vm.uid},domProps:{"checked":_vm.isChecked === true,"value":_vm.value},on:{"focus":_vm.onFocus,"blur":_vm.onBlur}},'input',_vm.$attrs,false),_vm.inputListeners)),_c('label',{class:[
      `${_vm.carbonPrefix}--checkbox-label`,
      {
        [`${_vm.carbonPrefix}--label--disabled`]: _vm.$attrs.disabled !== undefined && this.$attrs.disabled,
        [`${_vm.carbonPrefix}--checkbox-label__focus`]: _vm.hasFocus,
      },
    ],attrs:{"data-contained-checkbox-state":_vm.isChecked,"data-contained-checkbox-disabled":_vm.$attrs.disabled,"for":_vm.uid}},[_c('span',{class:[`${_vm.carbonPrefix}--checkbox-label-text`, { [`${_vm.carbonPrefix}--visually-hidden`]: _vm.hideLabel }]},[_vm._v(" "+_vm._s(_vm.label)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }