var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"tabs",staticClass:"cv-tabs",staticStyle:{"width":"100%"}},[_c('div',_vm._g(_vm._b({class:[`cv-tab ${_vm.carbonPrefix}--tabs--scrollable`, { [`${_vm.carbonPrefix}--tabs--container`]: _vm.container }],attrs:{"data-tabs":"","role":"navigation"},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;$event.preventDefault();$event.stopPropagation();return _vm.onRight.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;$event.preventDefault();$event.stopPropagation();return _vm.onLeft.apply(null, arguments)}]}},'div',_vm.$attrs,false),_vm.$listeners),[_c('button',{ref:"leftOverflow",class:[
        {
          [`${_vm.carbonPrefix}--tab--overflow-nav-button`]: _vm.horizontalOverflow,
          [`${_vm.carbonPrefix}--tab--overflow-nav-button--hidden`]: _vm.leftOverflowNavButtonHidden,
        },
      ],attrs:{"aria-hidden":"true","aria-label":"scroll left","tabIndex":"-1","type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (e => _vm.onOverflowClick(e, { direction: -1 })).apply(null, arguments)},"mousedown":function($event){$event.stopPropagation();$event.preventDefault();return (e => _vm.onOverflowMouseDown(e, { direction: -1 })).apply(null, arguments)},"mouseup":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onOverflowMouseUp.apply(null, arguments)}}},[_c('ChevronLeft16')],1),(!_vm.leftOverflowNavButtonHidden)?_c('div',{class:`${_vm.carbonPrefix}--tabs__overflow-indicator--left`}):_vm._e(),_c('ul',{ref:"tablist",class:`${_vm.carbonPrefix}--tabs--scrollable__nav`,attrs:{"role":"tablist"}},_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab.uid,class:[
          `cv-tabs-button  ${_vm.carbonPrefix}--tabs--scrollable__nav-item`,
          {
            [`${_vm.carbonPrefix}--tabs__nav-item--selected`]: _vm.selectedId == tab.uid,
            [`${_vm.carbonPrefix}--tabs__nav-item--disabled`]: _vm.disabledTabs.indexOf(tab.uid) !== -1,
            // TODO: remove scrollable in next major release
            [`${_vm.carbonPrefix}--tabs--scrollable__nav-item--disabled`]: _vm.disabledTabs.indexOf(tab.uid) !== -1,
            [`${_vm.carbonPrefix}--tabs--scrollable__nav-item--selected`]: _vm.selectedId == tab.uid,
          },
        ],attrs:{"role":"presentation"}},[_c('button',{ref:"link",refInFor:true,class:`${_vm.carbonPrefix}--tabs--scrollable__nav-link`,attrs:{"role":"tab","aria-controls":tab.uid,"aria-disabled":_vm.disabledTabs.indexOf(tab.uid) !== -1,"aria-selected":_vm.selectedId == tab.uid,"id":`${tab.uid}-link`,"eslint-disable-next-line":"","tabindex":// eslint-disable-nextx-line
            _vm.selectedId == tab.uid ? 0 : -1,"type":"button"},on:{"click":function($event){return _vm.onTabClick(tab.uid)}}},[_vm._v(" "+_vm._s(tab.label)+" ")])])}),0),(!_vm.rightOverflowNavButtonHidden)?_c('div',{class:`${_vm.carbonPrefix}--tabs__overflow-indicator--right`}):_vm._e(),_c('button',{ref:"rightOverflow",class:[
        {
          [`${_vm.carbonPrefix}--tab--overflow-nav-button`]: _vm.horizontalOverflow,
          [`${_vm.carbonPrefix}--tab--overflow-nav-button--hidden`]: _vm.rightOverflowNavButtonHidden,
        },
      ],attrs:{"aria-hidden":"true","aria-label":"scroll right","tabIndex":"-1","type":"button"},on:{"click":e => _vm.onOverflowClick(e, { direction: 1 }),"mousedown":e => _vm.onOverflowMouseDown(e, { direction: 1 }),"mouseup":_vm.onOverflowMouseUp}},[_c('ChevronRight16')],1)]),_c('div',{staticClass:"cv-tabs__panels"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }