var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    `cv-multi-select ${_vm.carbonPrefix}--multi-select__wrapper ${_vm.carbonPrefix}--list-box__wrapper`,
    {
      [`${_vm.carbonPrefix}--multi-select__wrapper--inline ${_vm.carbonPrefix}--list-box__wrapper--inline`]: _vm.inline,
      [`${_vm.carbonPrefix}--multi-select__wrapper--inline--invalid ${_vm.carbonPrefix}--list-box__wrapper--inline--invalid`]:
        _vm.inline && _vm.isInvalid,
      [`${_vm.carbonPrefix}--multi-select--filterable`]: _vm.filterable,
    },
  ],on:{"focusout":_vm.onFocusOut}},[(_vm.title)?_c('label',{class:[`${_vm.carbonPrefix}--label`, { [`${_vm.carbonPrefix}--label--disabled`]: _vm.disabled }],attrs:{"id":`${_vm.uid}-label`}},[_vm._v(_vm._s(_vm.title))]):_vm._e(),_c('div',_vm._b({class:[
      `${_vm.carbonPrefix}--multi-select ${_vm.carbonPrefix}--list-box`,
      {
        [`${_vm.carbonPrefix}--list-box--light`]: _vm.isLight,
        [`${_vm.carbonPrefix}--list-box--expanded`]: _vm.open,
        [`${_vm.carbonPrefix}--multi-select--invalid`]: _vm.isInvalid,
        [`${_vm.carbonPrefix}--multi-select--disabled`]: _vm.disabled,
        [`${_vm.carbonPrefix}--list-box--disabled`]: _vm.disabled,
        [`${_vm.carbonPrefix}--multi-select--inline`]: _vm.inline,
        [`${_vm.carbonPrefix}--list-box--inline`]: _vm.inline,
        [`${_vm.carbonPrefix}--multi-select--selected`]: _vm.dataValue.length > 0,
        [`${_vm.carbonPrefix}--combo-box`]: _vm.filterable,
      },
    ],attrs:{"aria-labelledby":`${_vm.uid}-label`,"role":"listbox","tabindex":"-1","data-invalid":_vm.isInvalid},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.onDown.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.onUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.onEnter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;$event.preventDefault();return _vm.onEsc.apply(null, arguments)}],"click":_vm.onClick}},'div',_vm.$attrs,false),[(_vm.isInvalid)?_c('WarningFilled16',{class:`${_vm.carbonPrefix}--list-box__invalid-icon`}):_vm._e(),_c('div',{ref:"button",class:`${_vm.carbonPrefix}--list-box__field`,attrs:{"role":"button","aria-haspopup":"true","aria-expanded":_vm.open ? 'true' : 'false',"aria-owns":_vm.uid,"aria-controls":_vm.uid,"tabindex":"0","type":"button","aria-label":_vm.open ? 'close menu' : 'open menu',"data-toggle":"true"}},[_c('cv-tag',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataValue.length > 0),expression:"dataValue.length > 0"}],ref:"tag",class:{ [`${_vm.carbonPrefix}--list-box__selection--multi`]: _vm.filterable && _vm.dataValue.length > 0 },style:(_vm.filterableTagOverride),attrs:{"disabled":_vm.disabled,"kind":_vm.filterTagKind,"filter":"","label":`${_vm.dataValue.length}`},on:{"remove":_vm.clearValues}}),(!_vm.filterable)?_c('span',{class:`${_vm.carbonPrefix}--list-box__label`},[_vm._v(_vm._s(_vm.label))]):[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter),expression:"filter"}],ref:"input",class:[
            `${_vm.carbonPrefix}--text-input`,
            { [`${_vm.carbonPrefix}--text-input--empty`]: !_vm.filter || _vm.filter.length === 0 },
          ],attrs:{"aria-controls":_vm.uid,"aria-autocomplete":"list","role":"combobox","aria-expanded":_vm.open ? 'true' : 'false',"autocomplete":"off","placeholder":_vm.label},domProps:{"value":(_vm.filter)},on:{"input":[function($event){if($event.target.composing)return;_vm.filter=$event.target.value},_vm.onInput],"focus":_vm.inputFocus,"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.inputClick.apply(null, arguments)}}}),(_vm.filter.length > 0)?_c('div',{class:`${_vm.carbonPrefix}--list-box__selection`,attrs:{"role":"button","tabindex":"0","title":"Clear filter"},on:{"click":function($event){$event.stopPropagation();return _vm.clearFilter.apply(null, arguments)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.stopPropagation();$event.preventDefault();return _vm.clearFilter.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();$event.preventDefault();}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"]))return null;$event.stopPropagation();$event.preventDefault();return _vm.clearFilter.apply(null, arguments)}}},[_c('Close16')],1):_vm._e()],_c('div',{class:[`${_vm.carbonPrefix}--list-box__menu-icon`, { [`${_vm.carbonPrefix}--list-box__menu-icon--open`]: _vm.open }],attrs:{"role":"button"}},[_c('chevron-down-16',{attrs:{"aria-label":_vm.open ? 'Close menu' : 'Open menu'}})],1)],2),_c('div',{ref:"list",class:`${_vm.carbonPrefix}--list-box__menu`,attrs:{"id":_vm.uid,"aria-labelledby":`${_vm.uid}-label`,"role":"listbox"}},_vm._l((_vm.dataOptions),function(item,index){return _c('div',{key:`multi-select-${index}`,ref:"option",refInFor:true,class:[
          `${_vm.carbonPrefix}--list-box__menu-item`,
          { [`${_vm.carbonPrefix}--list-box__menu-item--highlighted`]: _vm.highlighted === item.value },
        ],on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onItemClick(item.value)},"mousemove":function($event){return _vm.onMousemove(item.value)},"mousedown":function($event){$event.preventDefault();}}},[_c('div',{class:`${_vm.carbonPrefix}--list-box__menu-item__option`},[_c('cv-checkbox',{staticStyle:{"pointer-events":"none"},attrs:{"tabindex":"-1","form-item":false,"value":item.value,"name":item.name,"data-test":item.name,"label":item.label,"disabled":item.disabled},model:{value:(_vm.dataValue),callback:function ($$v) {_vm.dataValue=$$v},expression:"dataValue"}})],1)])}),0)],1),(_vm.isInvalid && !_vm.inline)?_c('div',{class:`${_vm.carbonPrefix}--form-requirement`},[_vm._t("invalid-message",function(){return [_vm._v(_vm._s(_vm.invalidMessage))]})],2):_vm._e(),(!_vm.inline && !_vm.isInvalid && _vm.isHelper)?_c('div',{class:[`${_vm.carbonPrefix}--form__helper-text`, { [`${_vm.carbonPrefix}--form__helper-text--disabled`]: _vm.disabled }]},[_vm._t("helper-text",function(){return [_vm._v(_vm._s(_vm.helperText))]})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }