var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    `${_vm.carbonPrefix}--structured-list`,
    {
      [`${_vm.carbonPrefix}--structured-list--selection`]: _vm.selectable,
      [`${_vm.carbonPrefix}--structured-list--condensed`]: _vm.condensed,
    },
  ],attrs:{"data-structured-list":_vm.selectable}},[_c('div',{class:`${_vm.carbonPrefix}--structured-list-thead`},[_c('div',{class:`${_vm.carbonPrefix}--structured-list-row ${_vm.carbonPrefix}--structured-list-row--header-row`},[_vm._t("headings"),(_vm.selectable)?_c('div',{class:`${_vm.carbonPrefix}--structured-list-th`}):_vm._e()],2)]),_c('div',{class:`${_vm.carbonPrefix}--structured-list-tbody`},[_vm._t("items")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }