var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`cv-definition-tooltip ${_vm.carbonPrefix}--tooltip--definition ${_vm.carbonPrefix}--tooltip--a11y`,attrs:{"id":_vm.uid}},[_c('button',{class:[
      `${_vm.carbonPrefix}--tooltip__trigger`,
      `${_vm.carbonPrefix}--tooltip--a11y`,
      `${_vm.carbonPrefix}--tooltip__trigger--definition`,
      `${_vm.carbonPrefix}--tooltip--${_vm.direction}`,
      `${_vm.carbonPrefix}--tooltip--align-${_vm.alignment}`,
    ],attrs:{"aria-describedby":`${_vm.uid}-label`,"type":"button"}},[_vm._v(" "+_vm._s(_vm.term)+" ")]),_c('div',{class:`${_vm.carbonPrefix}--assistive-text`,attrs:{"id":`${_vm.uid}-label`,"role":"tooltip"}},[_vm._v(_vm._s(_vm.definition))])])
}
var staticRenderFns = []

export { render, staticRenderFns }