var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cv-row",class:[
    `${_vm.carbonPrefix}--row`,
    {
      [`${_vm.carbonPrefix}--row--condensed`]: _vm.kind === 'condensed',
      [`${_vm.carbonPrefix}--row--narrow`]: _vm.kind === 'narrow',
    },
  ]},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }