var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cv-interactive-tooltip"},[_c('div',{class:`${_vm.carbonPrefix}--tooltip__label`,attrs:{"id":`${_vm.uid}-label`}},[_vm._t("label"),_c('button',{ref:"trigger",class:`${_vm.carbonPrefix}--tooltip__trigger`,attrs:{"aria-expanded":_vm.dataVisible ? 'true' : 'false',"aria-labelledby":`${_vm.uid}-label`,"aria-controls":`${_vm.uid}`,"aria-haspopup":"true","type":"button"},on:{"click":_vm.toggle,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;return _vm.onTriggerTab.apply(null, arguments)},"focusout":_vm.checkFocusOut}},[_vm._t("trigger",function(){return [_c('Information16')]})],2)],2),_c('div',{ref:"popup",class:[
      `${_vm.carbonPrefix}--tooltip`,
      {
        [`${_vm.carbonPrefix}--tooltip--shown`]: _vm.dataVisible,
        [`${_vm.carbonPrefix}--tooltip--${_vm.direction}`]: _vm.direction,
        [`${_vm.carbonPrefix}--tooltip--align-${_vm.alignment}`]: _vm.alignment,
      },
    ],style:({ left: _vm.left + 'px', top: _vm.top + 'px' }),attrs:{"id":_vm.uid,"aria-hidden":"true","data-floating-menu-direction":_vm.direction,"role":"dialog","aria-describedby":`${_vm.uid}-body`,"aria-labelledby":`${_vm.uid}-label`,"tabindex":"-1"},on:{"focusout":_vm.checkFocusOut,"mousedown":function($event){$event.preventDefault();return _vm.preventFocusOut.apply(null, arguments)}}},[_c('div',{ref:"beforeContent",staticClass:"cv-interactive-tooltip__before-content",staticStyle:{"position":"absolute","left":"-9999px","width":"1px","height":"1px"},attrs:{"tabindex":"0"},on:{"focus":_vm.focusBeforeContent}}),_c('span',{class:`${_vm.carbonPrefix}--tooltip__caret`}),_c('div',{class:`${_vm.carbonPrefix}--tooltip__content`},[_vm._t("content")],2),_c('div',{ref:"afterContent",staticClass:"cv-interactive-tooltip__after-content",staticStyle:{"position":"absolute","left":"-9999px","width":"1px","height":"1px"},attrs:{"tabindex":"0"},on:{"focus":_vm.focusAfterContent}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }