var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`cv-slider ${_vm.carbonPrefix}--form-item`},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label),expression:"label"}],class:[`${_vm.carbonPrefix}--label`, { [`${_vm.carbonPrefix}--label--disabled`]: _vm.disabled }],attrs:{"for":_vm.uid,"id":_vm.labelId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{class:`${_vm.carbonPrefix}--slider-container`},[_c('span',{class:`${_vm.carbonPrefix}--slider__range-label`},[_vm._v(_vm._s(_vm.internalMinLabel))]),_c('div',{class:[`${_vm.carbonPrefix}--slider`, { [`${_vm.carbonPrefix}--slider--disabled`]: _vm.disabled }],attrs:{"data-slider":"","data-slider-input-box":"#slider-input-box"}},[_c('div',{ref:"track",class:`${_vm.carbonPrefix}--slider__track`,on:{"click":_vm.onTrackClick}}),_c('div',{class:`${_vm.carbonPrefix}--slider__filled-track`,style:(`width: ${_vm.percentage};`)}),_c('div',{ref:"thumb",class:[
          `${_vm.carbonPrefix}--slider__thumb`,
          {
            [`${_vm.carbonPrefix}--slider__thumb--clicked`]: _vm.animateClick,
          },
        ],style:(`left: ${_vm.percentage};`),attrs:{"tabindex":"0","aria-labelledby":_vm.labelId},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])&&_vm._k($event.keyCode,"right",39,$event.key,["Right","ArrowRight"]))return null;if('button' in $event && $event.button !== 2)return null;$event.preventDefault();return _vm.onUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])&&_vm._k($event.keyCode,"left",37,$event.key,["Left","ArrowLeft"]))return null;if('button' in $event && $event.button !== 0)return null;$event.preventDefault();return _vm.onDown.apply(null, arguments)}],"mousedown":_vm.onStartDrag}}),_c('input',{ref:"range",class:`${_vm.carbonPrefix}--slider__input`,attrs:{"id":_vm.uid,"type":"range","step":_vm.step,"min":_vm.min,"max":_vm.max}})]),_c('span',{class:`${_vm.carbonPrefix}--slider__range-label`},[_vm._v(_vm._s(_vm.internalMaxLabel))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.internalValue),expression:"internalValue"}],ref:"inputBox",class:[
        `${_vm.carbonPrefix}--text-input ${_vm.carbonPrefix}--slider-text-input`,
        { [`${_vm.carbonPrefix}--text-input--light`]: _vm.isLight },
      ],attrs:{"type":"number","placeholder":_vm.min,"disabled":_vm.disabled},domProps:{"value":(_vm.internalValue)},on:{"change":_vm.onChange,"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.onUp.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.onDown.apply(null, arguments)}],"input":function($event){if($event.target.composing)return;_vm.internalValue=$event.target.value}}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }