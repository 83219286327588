var render = function render(){var _vm=this,_c=_vm._self._c;return _c('cv-wrapper',{staticClass:"cv-number-input",class:`${_vm.carbonPrefix}--form-item`,attrs:{"tag-type":_vm.formItem ? 'div' : ''}},[_c('div',{class:[
      `${_vm.carbonPrefix}--number`,
      {
        [`${_vm.carbonPrefix}--number--light`]: _vm.isLight,
        [`${_vm.carbonPrefix}--number--helpertext`]: _vm.isHelper,
        [`cv-number-input`]: !_vm.formItem,
        [`${_vm.carbonPrefix}--number--mobile`]: _vm.mobile,
      },
    ],attrs:{"data-invalid":_vm.isInvalid}},[_c('label',{directives:[{name:"show",rawName:"v-show",value:(_vm.label),expression:"label"}],class:`${_vm.carbonPrefix}--label`,attrs:{"for":_vm.uid}},[_vm._v(_vm._s(_vm.label))]),_c('div',{class:[
        `${_vm.carbonPrefix}--number__input-wrapper`,
        { [`${_vm.carbonPrefix}--number__input-wrapper--warning`]: _vm.isWarn },
      ]},[_c('input',_vm._g(_vm._b({ref:"input",attrs:{"data-invalid":_vm.isInvalid,"id":_vm.uid,"type":"number","disabled":_vm.disabled,"step":_vm.step,"min":_vm.min,"max":_vm.max},domProps:{"value":_vm.internalValue}},'input',_vm.$attrs,false),_vm.inputListeners)),(_vm.isInvalid)?_c('WarningFilled16',{class:_vm.iconClasses}):_vm._e(),(_vm.isWarn && !_vm.isInvalid)?_c('WarningAltFilled16',{class:_vm.iconClasses}):_vm._e(),_c('div',{class:`${_vm.carbonPrefix}--number__controls`},[_c('button',{class:`${_vm.carbonPrefix}--number__control-btn down-icon`,attrs:{"type":"button","aria-label":_vm.ariaLabelForDownButton,"disabled":_vm.disabled,"aria-live":"polite","aria-atomic":"true"},on:{"click":_vm.doDown}},[_c('Subtract16',{staticClass:"down-icon"})],1),_c('div',{class:`${_vm.carbonPrefix}--number__rule-divider`}),_c('button',{class:`${_vm.carbonPrefix}--number__control-btn up-icon`,attrs:{"type":"button","aria-label":_vm.ariaLabelForUpButton,"disabled":_vm.disabled,"aria-live":"polite","aria-atomic":"true"},on:{"click":_vm.doUp}},[_c('Add16',{staticClass:"up-icon"})],1),_c('div',{class:`${_vm.carbonPrefix}--number__rule-divider`})])],1),(_vm.isInvalid)?_c('div',{class:`${_vm.carbonPrefix}--form-requirement`},[_vm._t("invalid-message",function(){return [_vm._v(_vm._s(_vm.invalidMessage))]})],2):_vm._e(),(_vm.isWarn && !_vm.isInvalid)?_c('div',{class:`${_vm.carbonPrefix}--form-requirement`},[_vm._t("warn-text",function(){return [_vm._v(_vm._s(_vm.warnText))]})],2):_vm._e(),(!(_vm.isInvalid || _vm.isWarn) && _vm.isHelper)?_c('div',{class:`${_vm.carbonPrefix}--form__helper-text`},[_vm._t("helper-text",function(){return [_vm._v(_vm._s(_vm.helperText))]})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }