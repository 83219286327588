var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    `cv-content-switcher ${_vm.carbonPrefix}--content-switcher`,
    {
      [`${_vm.carbonPrefix}--content-switcher--light`]: _vm.isLight,
      [`${_vm.carbonPrefix}--content-switcher--${_vm.size}`]: _vm.size,
    },
  ],attrs:{"data-content-switcher":"","role":"tablist"}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }